/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./SwitchButton.scss";
/* Common */
import * as Constants from '../../../common/Constants';
import colors from '../../../common/Colors.scss';

class SwitchButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
         tabSelected : 0,
         style_selected   : {"background": colors.yellow, "color": colors.lightBlack},
         style_unselected : {"background": "transparent", "color": colors.lightBlack },
      }
   }

   /* Select the user type */
   _selectTab = (id) => {
      this.setState({ tabSelected : id })
      this.props.onSelectType(id);
   }

   
	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
   //------------------------------------------------------------//

   _getColors(id){
      if(id === this.state.tabSelected) return this.state.style_selected;
      else return this.state.style_unselected;   
   }


	render(){
		return(
			<div className="switchButton-container">
            <div className="switchButton-btn" style={this._getColors(0)} onClick={() => this._selectTab(0)}>
               {Constants.REPAIRED}
            </div>
            <div className="switchButton-btn" style={this._getColors(1)} onClick={() => this._selectTab(1)}>
               {Constants.REPAIRMAN}
            </div>
			</div>
		);
	}
}

export default SwitchButton;

