/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./TitleDivider.scss";

class TitleDivider extends Component {
   
   /* Open/Close tab */
   _onOpen = () => {
      this.props.onOpen();
   }
  
   render() {
      let {isOpen, title} = this.props;

      return (
         <div className="title-divider" onClick={this._onOpen}>
            {title}
            <div className="title-divider__icon">
               {isOpen &&(<i className='bx bxs-chevron-up bx-sm'></i>)}
               {!isOpen &&(<i className='bx bxs-chevron-down bx-sm'></i>)}
            </div>
         </div>
      );
   }
}

export default TitleDivider;

