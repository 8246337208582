/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./PriceItem.scss";

class PriceItem extends Component {
   constructor(props) {
      super(props);
      this.state = {
         displayPriceButton : false,
         displayMultiButton : false
      }
      this._handleChangePrice  = this._handleChangePrice.bind(this);
      this._handleChangeMulti  = this._handleChangeMulti.bind(this);
      this._onSavePrice        = this._onSavePrice.bind(this);
      this._onSaveMulti        = this._onSaveMulti.bind(this);

   }

   /* Change the value */
   _handleChangePrice(event) { 
      this.setState({displayPriceButton:true}); 
      this.props.onChangePrice(event);
   }

   /* Save the value */
   _onSavePrice(event) { 
      this.setState({displayPriceButton:false}); 
      this.props.onSavePrice(event);
   }

   /* Change the value */
   _handleChangeMulti(event) { 
      this.setState({displayMultiButton:true}); 
      this.props.onChangeMulti(event);
   }

   /* Save the value */
   _onSaveMulti(event) { 
      this.setState({displayMultiButton:false}); 
      this.props.onSaveMulti(event);
   }
  
   render() {
      let {icon, text, priceValue, multiValue} = this.props;
      let {displayPriceButton, displayMultiButton} = this.state;

      return (
         <div className="price">
            <div className="row">
               {/* Icon */}
               <div className="price-icon">{icon}</div>

               {/* Text */}
               <div className="price-text">{text}</div>

               {/* Input */}
               <div className="price-input">
                  <input value={priceValue} onChange={this._handleChangePrice} type="number" className="price-input-field"></input> €
               </div>

               {/* Button */}
               {displayPriceButton &&(<div className="price-button" onClick={() => this._onSavePrice()}>VALIDER</div>)}
            </div>
            <div className="row">
               {/* Text */}
               <div className="price-text --orange">Multiplicateur {'>'}3,5 Tonnes</div>

               {/* Input */}
               <div className="price-input">
                  <input value={multiValue} onChange={this._handleChangeMulti} type="number" className="price-input-field"></input> %
               </div>

               {/* Button */}
               {displayMultiButton &&(<div className="price-button" onClick={() => this._onSaveMulti()}>VALIDER</div>)}
            </div>
         </div>
      );
   }
}

export default PriceItem;

