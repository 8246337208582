/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./RepairmanFile.scss";
/* Services */
import { storage } from '../../../services/FirebaseCloudService';
import StringService from "../../../services/StringService";
//Class
const stringService = new StringService();

class RepairmanFile extends Component {
   constructor(props) {
		super(props);
		this.state = {
         itemChecked : false,
      }
   }

   /* Check the file */
   _onCheck() {
      let {itemChecked} = this.state;
      let check = !itemChecked;
      this.setState({ itemChecked: check })
      this.props.onClick(check);
   }

   /* Open the selected file */
   _openFile(){
      let {user, fileCode} = this.props;

      storage.ref('users/'+user['phoneID']+'/docs/'+fileCode).getDownloadURL().then((url) => {
         window.open(url, "_blank");
      }).catch((error) => {
         if(error.code){
            stringService.toast(stringService.getFirebaseStorageError(error.code));
         }
      });
   }
   
	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
   //------------------------------------------------------------//

	render(){
      let {itemChecked} = this.state;
      let {title} = this.props;

		return(
			<div className="document">
            <div className="document-cb" onClick={() => this._onCheck()}>
               {itemChecked &&(<i className='bx bx-x bx-md'></i>)}
            </div>
            <div className="document-label" onClick={() => this._openFile()}>
               <div className="document-label-text">
                  {title}
               </div>
               <div className="document-label-icon">
                  <i className='bx bx-file-blank bx-sm'></i>
               </div>
            </div>
         </div>
		);
	}
}

export default RepairmanFile;

