/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./MiniButton.scss";

class MiniButton extends Component {

   /* Select the user type */
   _onClick() {
      let {disable} = this.props;
      if(!disable) this.props.onClick();
   }

	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
   //------------------------------------------------------------//

   

	render(){
      let {icon, label, color} = this.props;

		return(
			<div className={'miniButton --'+color} onClick={() => this._onClick()}>
            {icon &&(<div className="miniButton__icon">
               {icon}
            </div>)}
            <div className="miniButton__label">
               {label}
            </div>
			</div>
		);
	}
}

export default MiniButton;

