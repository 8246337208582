/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./Info.scss";
import colors from '../../common/Colors.scss';
/* Plugins */
import { CircleSpinner } from "react-spinners-kit";
import { withRouter } from 'react-router-dom';

class InfoComponent extends Component {
   constructor(props) {
      super(props);
      this.state = {
         tabSelected : 0,
      }
		this._onLogin = this._onLogin.bind(this);

   }

   /* Back to login page */
   _onLogin(){
      this.props.history.push('/');
   }
  
   render() {
      let {text, isLoading, backToLogin} = this.props;

      return (
         <div className="info-container">
            {isLoading &&(<CircleSpinner size={40} color={colors.yellow} loading={isLoading} />)}
            <div className="info-text">
               {text}
            </div>
            {backToLogin &&(<input type="submit" value="Connexion" className="main-button" onClick={this._onLogin}></input>)}
         </div>
      );
   }
}

export default withRouter(InfoComponent);

