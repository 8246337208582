import firebase from 'firebase';

// Initialize Firebase
var config = {
    apiKey: "AIzaSyCBvkkrbMxjVt____wuXCYTgbfJb2KiI3s",
    authDomain: "mybootlid.firebaseapp.com",
    databaseURL: "https://mybootlid.firebaseio.com",
    projectId: "mybootlid",
    storageBucket: "mybootlid.appspot.com",
    messagingSenderId: "62698926662",
    appId: "1:62698926662:web:b3004cf2101489de10d047",
    measurementId: "G-GJ282X15KP"
}; 

const firebaseApp = firebase.initializeApp(config);
const auth    = firebaseApp.auth();
const db      = firebase.firestore();
const func    = firebase.functions();
const storage = firebase.storage();
firebase.analytics();

export {auth, db, func, storage}