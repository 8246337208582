/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./Drawer.scss";
import colors from '../../common/Colors.scss';
/* Common */
import * as Constants from '../../common/Constants';
/* Images */
import { ReactComponent as MyBootlidIcon }    from '../../assets/icon.svg';
import { ReactComponent as ServicePriceIcon } from '../../assets/prix.svg';
// import { ReactComponent as HistoryIcon }      from '../../assets/historiques.svg';
import { ReactComponent as UserIcon }         from '../../assets/utilisateurs.svg';
// import { ReactComponent as FAQIcon }          from '../../assets/FAQ.svg';
// import { ReactComponent as CancelIcon }       from '../../assets/annulation.svg';

class DrawerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style_selected   : {"background": colors.yellow, "color": colors.lightBlack},
            style_unselected : {"background": "transparent", "color": colors.lightBlack },

            tabSelected : 0
        }
    }

    /* Select the state of the draw */
    _selectTab = (id) => {
        this.setState({
            tabSelected : id
        })
        this.props.onSelectTab(id);
    }

    /* Select the color of the draw */
    _getColors(id){
        if(id === this.state.tabSelected) return this.state.style_selected;
        else return this.state.style_unselected;
    }

    _displayContent(){
        return(
            <div>
            {/* Logo */}
                <div className="drawer-logo">
                    <MyBootlidIcon />
                </div>

            {/* Services prices */}
                <div className="drawer-button" style={this._getColors(0)} onClick={() => this._selectTab(0)}>
                    <div className="drawer-button-icon">
                        <ServicePriceIcon />
                    </div>
                    <div className="drawer-button-text">{Constants.SERVICES_PRICES}</div>
                </div>

            {/* History */}
                {/* <div className="drawer-button" style={this._getColors(1)} onClick={() => this._selectTab(1)}>
                    <div className="drawer-button-icon">
                        <HistoryIcon />
                    </div>
                    <div className="drawer-button-text">{Constants.HISTORY}</div>
                </div> */}

            {/* Users */}
                <div className="drawer-button" style={this._getColors(2)} onClick={() => this._selectTab(2)}>
                    <div className="drawer-button-icon">
                        <UserIcon />
                    </div>
                    <div className="drawer-button-text">{Constants.USERS}</div>
                </div>

            {/* FAQ */}
                {/* <div className="drawer-button" style={this._getColors(3)} onClick={() => this._selectTab(3)}>
                    <div className="drawer-button-icon">
                        <FAQIcon />
                    </div>
                    <div className="drawer-button-text">{Constants.FAQ}</div>
                </div> */}

            {/* Cancel */}
                {/* <div div className="drawer-button" style={this._getColors(4)} onClick={() => this._selectTab(4)}>
                    <div className="drawer-button-icon">
                        <CancelIcon />
                    </div>
                    <div className="drawer-button-text">{Constants.CANCEL}</div>
                </div> */}

            </div>
        )
    }

    render() {
        return (
            <div className="drawer-container">
                {this._displayContent()}
            </div>
        );
    }
}

export default DrawerComponent;

