import { db,func } from './FirebaseCloudService';
import * as Constants from '../common/Constants';


class ApiService{

   //-----------------------------------------------//
   //------------------- GET -----------------------//
   //-----------------------------------------------//

   /* Check user can access to dashboard */
   async getUserCanAccess(userID){
      return db.collection('admin').doc(userID).get().then((canAccess) => {
         return canAccess.exists;
      }).catch((error) => { 
         console.log(error)
         // return false;
       });
   }

   /* Get prices */
   async getPrices(){
      return db.collection('constants').doc('prices').get().then((prices) => {
         return prices.data();
      }).catch(err => { console.error(err) });
   }

   /* Get all users */
   async getAllUsers(){
      let users = {
         0 : [], // repaired 
         1 : {  // repairman
            "pro"  : [],
            "part" : [],
         }, 
      }
      await db.collection('users').get().then((querySnapshot) => {
         querySnapshot.forEach((doc) => {
            if(doc.data()["repairman"] != null) {
               if(doc.data()["repairman"]['isPro']) users[1]["pro"].push(doc.data());
               else users[1]["part"].push(doc.data());
            }
            else users[0].push(doc.data())
         });
      }).catch((error) => {
         console.log("Error getting documents: ", error);
      });
      return users;
   }


   /* Get number of repairmen */
   async getRepairmenCount(){
      const repairmen = await db.collection('users').where('repairman', '!=', null).get();
      return repairmen.size;
   }


   //-----------------------------------------------//
   //------------------- SET -----------------------//
   //-----------------------------------------------//

   /* Set prices */
   async setPrices(price, value){
      return db.collection('constants').doc('prices').set({
         [price] : parseFloat(value)
      },{ merge: true })
      .then(() => { return true;})
      .catch(err => { console.error(err); return false; });
   }


   /* Set multiplicator */
   async setMulti(multi, value){
      return db.collection('constants').doc('prices').set({
         [multi] : parseFloat(value)
      },{ merge: true })
      .then(() => { return true;})
      .catch(err => { console.error(err); return false; });
   }


   //-----------------------------------------------//
   //------------ FIREBASE FUNCTIONS ---------------//
   //-----------------------------------------------//

   /* Set prices */
   async restrictUser(userID, hasToBeDisabled){
      var functionFB = func.httpsCallable(Constants.ENV+'_restrictUser');
      return functionFB({ 
         userID: userID, 
         hasToBeDisabled : hasToBeDisabled
      }).then((result) => {
        return result.data;
      }).catch((error) => {
         console.log(error);
         return error;
      });
   }


   /* Warn user for invalid documents */
   async warnUser(warnNumber, user, driverCard, assurance, vehicleReg, kbis){
      var functionFB = func.httpsCallable(Constants.ENV+'_warnUserInvalidDocs');
      return functionFB({ 
         userID          : user['phoneID'], 
         userName        : user['name'], 
         userSurname     : user['surname'], 
         userMail        : user['mail'], 
         userWarnedTimes : warnNumber, 
         driverCard      : driverCard,
         assurance       : assurance,
         vehicleReg      : vehicleReg,
         kbis            : kbis,
      }).then((result) => {
         return result.data;
      }).catch((error) => {
         console.log(error);
         return error;
      });
   }

   /* Warn user for invalid documents */
   async validDocs(user){
      var functionFB = func.httpsCallable(Constants.ENV+'_validDocs');
      return functionFB({ 
         userID : user['phoneID'], 
      }).then((result) => {
         return result.data;
      }).catch((error) => {
         console.log(error);
         return error;
      });
   }
}

export default ApiService;