/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./ViewPrices.scss";
/* Common */
import * as Constants from '../../../common/Constants';
import colors from '../../../common/Colors.scss';
/* Assets */
import { ReactComponent as PunctureIcon }    from '../../../assets/crevaison.svg';
import { ReactComponent as BatteryIcon }     from '../../../assets/batterie.svg';
import { ReactComponent as FuelIcon }        from '../../../assets/essence.svg';
import { ReactComponent as ObservationIcon } from '../../../assets/constat.svg';
import { ReactComponent as RemorqIcon }      from '../../../assets/depannage.svg';
/* Components */
import TitleDivider from "../../TitleDivider/TitleDivider";
/* Plugins */
import { CircleSpinner } from "react-spinners-kit";
/* Services */
import StringService from '../../../services/StringService';
import ApiService from '../../../services/ApiService';
import PriceItem from "../../PriceItem/PriceItem";
//Class
const stringService = new StringService();
const apiService = new ApiService();

class ViewPrices extends Component {
	constructor(props) {
		super(props);
		this.state = {
         isLoading       : true,

         multiPartBattery     : 0,
         multiPartFuel        : 0,
         multiPartObservation : 0,
         multiPartPuncture    : 0,
         multiProBattery      : 0,
         multiProFuel         : 0,
         multiProObservation  : 0,
         multiProPuncture     : 0,
         multiRemorq          : 0,

         partBattery     : 0,
         partFuel        : 0,
         partObservation : 0,
         partPuncture    : 0,
         proBattery      : 0,
         proFuel         : 0,
         proObservation  : 0,
         proPuncture     : 0,
         remorq          : 0,

         isTabProOpen  : true,
         isTabPartOpen : true,
      }
      this._getPricesFromProps = this._getPricesFromProps.bind(this);
      this._handleChangeValue  = this._handleChangeValue.bind(this);
		this._savePrice          = this._savePrice.bind(this);
		this._saveMulti          = this._saveMulti.bind(this);
   }
   componentDidMount(){
      if(this.props.prices !== {}){
         this._getPricesFromProps();
      }
	}
   componentDidUpdate(prevProps, prevState){
      if(prevProps.prices !== this.props.prices){
         this._getPricesFromProps();
      }
   }

   
   //------------------------------------------------------------//
	//------------------------- DATA -----------------------------//
   //------------------------------------------------------------//

   _getPricesFromProps(){
      let {prices} = this.props; 

      this.setState({
         isLoading       : false,

         multiPartBattery     : prices['multiPartBattery']    *100-100,
         multiPartFuel        : prices['multiPartFuel']       *100-100,
         multiPartObservation : prices['multiPartObservation']*100-100,
         multiPartPuncture    : prices['multiPartPuncture']   *100-100,
         multiProBattery      : prices['multiProBattery']     *100-100,
         multiProFuel         : prices['multiProFuel']        *100-100,
         multiProObservation  : prices['multiProObservation'] *100-100,
         multiProPuncture     : prices['multiProPuncture']    *100-100,
         multiRemorq          : prices['multiRemorq']         *100-100,

         partBattery     : prices['partBattery'],
         partFuel        : prices['partFuel'],
         partObservation : prices['partObservation'],
         partPuncture    : prices['partPuncture'],
         proBattery      : prices['proBattery'],
         proFuel         : prices['proFuel'],
         proObservation  : prices['proObservation'],
         proPuncture     : prices['proPuncture'],
         remorq          : prices['remorq'],
      });
   }
   
   /* Change values */
   _handleChangeValue(event, state) { this.setState({[state]: event.target.value}); }

   /* Save PRice */
   async _savePrice(stateText, value, constant) { 
      let priceSaved = await apiService.setPrices(stateText, value);
      if(priceSaved) stringService.toast(constant);
      this.props.onSaved();
   }

   /* Save Multiplicator */
   async _saveMulti(stateText, value, constant) { 
      var result = (value / 100.0)+1;
      let multiSaved = await apiService.setPrices(stateText, result);
      if(multiSaved) stringService.toast(constant);
      this.props.onSaved();
   }

   /* Open tab */
   _openTab(tabType){
      if(tabType === "pro") this.setState({isTabProOpen : !this.state.isTabProOpen})
      else this.setState({isTabPartOpen : !this.state.isTabPartOpen})
   }

	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
   //------------------------------------------------------------//

   /* Display a title */
   _displayTitle(title){
      return(
         <div className="subtitle">
            {title}
         </div>
      );
   }

   /* Display a line item */
   _displayItem(icon, text, priceValue, statePriceText, onSavedPriceText, multiValue, stateMultiText, onSavedMultiText){
      return(
         <PriceItem 
            icon={icon} 
            text={text} 
            priceValue={priceValue} 
            onChangePrice={(e) => this._handleChangeValue(e, statePriceText)} 
            onSavePrice={() => this._savePrice(statePriceText, priceValue, onSavedPriceText)}
            multiValue={multiValue}
            onChangeMulti={(e) => this._handleChangeValue(e, stateMultiText)}
            onSaveMulti={() => this._saveMulti(stateMultiText, multiValue, onSavedMultiText)}
         />
      );
   }


	render(){
      let {isLoading, isTabProOpen, isTabPartOpen,
         proPuncture, proBattery, proFuel, proObservation, remorq, partPuncture, partBattery, partFuel, partObservation,
         multiProPuncture, multiProBattery, multiProFuel, multiProObservation,  multiRemorq, multiPartPuncture, multiPartBattery, multiPartFuel, multiPartObservation} = this.state;
      
		return(
			<div className="prices-container">
            {isLoading &&(
               <div className="prices-loader">
                  <CircleSpinner size={40} color={colors.yellow} loading={isLoading} />
               </div>
            )}
            {!isLoading &&(
               <div className="prices-content">
                  <TitleDivider title="Dépanneurs professionnels" isOpen={isTabProOpen} onOpen={() => this._openTab("pro")}></TitleDivider>
                  {isTabProOpen && (<div className="prices-items">
                     {this._displayItem(<PunctureIcon/>,    Constants.PUNCTURE,    proPuncture,    'proPuncture',    Constants.PRO_PUNCTURE_SAVED, multiProPuncture,    'multiProPuncture',    Constants.MULTI_PRO_PUNCTURE_SAVED)}
                     {this._displayItem(<BatteryIcon/>,     Constants.BATTERY,     proBattery,     'proBattery',     Constants.PRO_BATTERY_SAVED,  multiProBattery,     'multiProBattery',     Constants.MULTI_PRO_BATTERY_SAVED)}
                     {this._displayItem(<FuelIcon/>,        Constants.FUEL,        proFuel,        'proFuel',        Constants.PRO_FUEL_SAVED,     multiProFuel,        'multiProFuel',        Constants.MULTI_PRO_FUEL_SAVED)}
                     {this._displayItem(<ObservationIcon/>, Constants.OBSERVATION, proObservation, 'proObservation', Constants.PRO_OBS_SAVED,      multiProObservation, 'multiProObservation', Constants.MULTI_PRO_OBS_SAVED)}
                     {this._displayItem(<RemorqIcon/>,      Constants.REMORQ,      remorq,         'remorq',         Constants.REMORQ_SAVED,       multiRemorq,         'multiRemorq',         Constants.MULTI_REMORQ_SAVED)}
                  </div>)}
                  
                  <TitleDivider title="Dépanneurs particuliers" isOpen={isTabPartOpen} onOpen={() => this._openTab("part")}></TitleDivider>
                  {isTabPartOpen && (<div className="prices-items">
                     {this._displayItem(<PunctureIcon/>,    Constants.PUNCTURE,    partPuncture,    'partPuncture',    Constants.PART_PUNCTURE_SAVED, multiPartPuncture,     'multiPartPuncture',    Constants.MULTI_PART_PUNCTURE_SAVED)}
                     {this._displayItem(<BatteryIcon/>,     Constants.BATTERY,     partBattery,     'partBattery',     Constants.PART_BATTERY_SAVED,  multiPartBattery,      'multiPartBattery',     Constants.MULTI_PART_BATTERY_SAVED)}
                     {this._displayItem(<FuelIcon/>,        Constants.FUEL,        partFuel,        'partFuel',        Constants.PART_FUEL_SAVED,     multiPartFuel,         'multiPartFuel',        Constants.MULTI_PART_FUEL_SAVED)}
                     {this._displayItem(<ObservationIcon/>, Constants.OBSERVATION, partObservation, 'partObservation', Constants.PART_OBS_SAVED,       multiPartObservation, 'multiPartObservation', Constants.MULTI_PART_OBS_SAVED)}
                  </div>)}
               </div>
            )}
			</div>
		);
	}
}

export default ViewPrices;

