/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./ViewUsers.scss";
/* Components */
import SwitchButton from "../../Buttons/SwitchButton/SwitchButton";
import UserCard     from "../../UserCard/UserCard";
import TitleDivider from "../../TitleDivider/TitleDivider";

class ViewUsers extends Component {
	constructor(props) {
		super(props);
		this.state = {
         userType  : 0,
         isTabDepanneOpen : true,
         isTabProOpen     : true,
         isTabPartOpen    : true,
      }
   }
   //------------------------------------------------------------//
	//------------------------- DATA -----------------------------//
   //------------------------------------------------------------//

   /* Change user type to be displayed */
   _selectUserType = (id) => {
		this.setState({userType: id});
	}

   /* Open tab */
   _openTab(tabType){
      if(tabType === "pro") this.setState({isTabProOpen : !this.state.isTabProOpen})
      else if(tabType === "part") this.setState({isTabPartOpen : !this.state.isTabPartOpen})
      else this.setState({isTabDepanneOpen : !this.state.isTabDepanneOpen})
   }
   
	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
   //------------------------------------------------------------//

   /* Display the informations panel */
   _displayLegend(){
      return(
         <div className="users-content-legend">
            <div className="legendLine">
               <div className="legendLine__icon disabled"><i className='bx bxs-lock bx-sm'></i></div>
               <div className="legendLine__text">L'utilisateur est bloqué</div>
            </div>
            <div className="legendLine">
               <div className="legendLine__icon warn"><i className='bx bxs-error bx-sm'></i></div>
               <div className="legendLine__text"> (Dépanneurs) L'utilisateur a reçu un ou plusieurs avertissements pour documents invalides</div>
            </div>
            <div className="legendLine">
               <div className="legendLine__icon valid"><i className='bx bxs-check-circle bx-sm'></i></div>
               <div className="legendLine__text">(Dépanneurs) L'utilisateur a été validé</div>
            </div>
         </div>
      );
   }
   
   /* Change user type to be displayed */
   _displayUsersList(userType, users){
      let {isTabDepanneOpen, isTabProOpen, isTabPartOpen} = this.state;
      if(userType === 0){
         return (
            <div className="users">
               <div className="users-block">
                  <TitleDivider title={"Dépannés ("+users[userType].length+")"} isOpen={isTabDepanneOpen} onOpen={() => this._openTab("depanne")}></TitleDivider>
                  {isTabDepanneOpen && (<div className="users-block__list">
                     {users[userType].map(user => {return  <UserCard key={user['phoneID']} user={user}></UserCard> })}
                   </div>)}
               </div>
            </div>
         )
         
      }else{
         return(
            <div className="users">
               <div className="users-block">
                  <TitleDivider title={"Dépanneurs professionnels ("+users[userType]["pro"].length+")"} isOpen={isTabProOpen} onOpen={() => this._openTab("pro")}></TitleDivider>
                  {isTabProOpen &&(<div className="users-block__list">
                     {users[userType]["pro"].map(user => { return <UserCard key={user['phoneID']} user={user}></UserCard>})}
                  </div>)}
               </div>
               <div className="users-block">
                  <TitleDivider title={"Dépanneurs particuliers ("+users[userType]["part"].length+")"} isOpen={isTabPartOpen} onOpen={() => this._openTab("part")}></TitleDivider>
                  {isTabPartOpen &&(<div className="users-block__list">
                     {users[userType]["part"].map(user => { return <UserCard key={user['phoneID']} user={user}></UserCard>})}
                  </div>)}
               </div>
            </div>
         );
      }
	}


	render(){
      let {userType} = this.state;
      let {users} = this.props;
      
		return(
			<div className="users-container">
            <div className="users-content">

               {this._displayLegend()}

               <div className="users-content-switch">
                  <SwitchButton onSelectType={this._selectUserType} />
               </div>

               <div className="users-content-body">
                  {this._displayUsersList(userType, users)}
               </div>

            </div>
			</div>
		);
	}
}

export default ViewUsers;

