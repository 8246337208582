/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./MainPage.scss";
import Drawer     from "../../components/Drawer/Drawer";
import ViewPrices from "../../components/Views/ViewPrices/ViewPrices";
import ViewUsers  from "../../components/Views/ViewUsers/ViewUsers";
/* Common */
import * as Constants from '../../common/Constants';
/* Components */
import { ReactComponent as LogoutIcon }  from '../../assets/deconnexion.svg';
import InfoComponent from "../../components/Info/Info";
/* Services */
import ApiService 	from '../../services/ApiService';
import {auth}        from "../../services/FirebaseCloudService"
import StringService from '../../services/StringService';
//Class
const apiService    = new ApiService();
const stringService = new StringService();

class MainPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			tabSelected    : 0,
			pricesImported : null,
			usersImported  : null,
			isLogged       : true,
			isLoading      : true
		}
		this._getDatas 			= this._getDatas.bind(this);
		this._getPrices 			= this._getPrices.bind(this);
		this._logout 				= this._logout.bind(this);
		this._checkUserIsLogged = this._checkUserIsLogged.bind(this);
	}
	componentDidMount(){
		this._isMounted = true;
		this._getDatas();
	}

	//------------------------------------------------------------//
	//------------------------- DATA -----------------------------//
	//------------------------------------------------------------//
	
	/* Get the data from Firebase */
	async _getDatas(){
		setTimeout(async ()  => { 
			let isLogged = await this._checkUserIsLogged();
			if(isLogged){
				let prices = await apiService.getPrices();
				let users  = await apiService.getAllUsers();

				users[0] 		  = stringService.sortByValue(users[0], "name");
				users[1]["pro"]  = stringService.sortByValue(users[1]["pro"], "name");
				users[1]["part"] = stringService.sortByValue(users[1]["part"], "name");

				let depannésCount = users[0].length;
				let depanneurProCount = users[1]["pro"].length;
				let depanneurPartCount = users[1]["part"].length;
				let depanneurAllCount = depanneurProCount+depanneurPartCount;
				let AllCount = depannésCount+depanneurAllCount;

				console.log("----------");
				console.log("Dépannés : "+depannésCount);
				console.log("----------");
				console.log("Depanneurs pro: "+depanneurProCount);
				console.log("Depanneurs part: "+depanneurPartCount);
				console.log("Depanneurs (tous): "+depanneurAllCount);
				console.log("----------");
				console.log("ALL users : "+AllCount);
				console.log("----------");
		
				this.setState({
					isLoading      : false,
					pricesImported : prices,
					usersImported 	: users
				});
			}
			this.setState({
				isLoading : false,
				isLogged  : isLogged
			});
		}, 2000);
	}

	/* Get only prices */
	async _getPrices(){
		let prices = await apiService.getPrices();
		this.setState({ pricesImported : prices});
	}

	/* Check if user is logged */
	async _checkUserIsLogged(){
		if(auth.currentUser === null){
			return false;
		}else return true;
	}

	/* Logout user */
	_logout(){
		this.props.history.push('/');
		auth.signOut();
	}

	/* Select the page to display */
	_selectTab = (id) => {
		this.setState({tabSelected: id});
	}


	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
	//------------------------------------------------------------//

	/* Chnage the title depending on the tab selected */
	_displayTitle(){
		let {tabSelected} = this.state;

		if(tabSelected === 0) return Constants.SERVICES_PRICES;
		else if(tabSelected === 1) return Constants.HISTORY;
		else if(tabSelected === 2) return Constants.USERS;
		else if(tabSelected === 3) return Constants.FAQ;
		else if(tabSelected === 4) return Constants.CANCEL;
	}

	render(){
		let {tabSelected, isLogged, isLoading, pricesImported, usersImported} = this.state;

		return(
			<div className="main-container">
				{!isLoading && isLogged &&(<>
					<Drawer onSelectTab={this._selectTab}></Drawer>
					<div className="main-body">

						{/* Title + Button deconnect */}
						<div className="main-body-title">
							<div className="main-body-title-text">{this._displayTitle()}</div>
							<div className="main-body-title-logout" onClick={this._logout}>
								<LogoutIcon />
								<div className="main-body-title-logout-text">Déconnexion</div>
							</div>
						</div>

						{/* Pages */}
						<div className="main-body-page">
							{tabSelected === 0 &&(<ViewPrices prices={pricesImported} onSaved={this._getPrices}></ViewPrices>)}
							{tabSelected === 2 &&(<ViewUsers users={usersImported}></ViewUsers>)}
						</div>
					</div>
				</>)}
				{!isLoading && !isLogged &&(<InfoComponent isLoading={false} backToLogin={true} text={Constants.UNLOGGED}></InfoComponent>)}
				{isLoading &&(<InfoComponent isLoading={true} text={Constants.LOADING}></InfoComponent>)}
			</div>
		);
	}
}

export default MainPage;

