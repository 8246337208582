/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./LoginPage.scss";
/* Images */
import iconMyBootlid  from '../../assets/icon-black.png';
/* Common */
import * as Constants from '../../common/Constants';
/* Components */
import InfoComponent from "../../components/Info/Info";
/* Services */
import {auth} from "../../services/FirebaseCloudService"
import StringService from '../../services/StringService';
import ApiService from "../../services/ApiService";
/* Plugins */
import { withRouter } from 'react-router-dom';
// Class
const stringService = new StringService();
const apiService    = new ApiService();


class LoginPage extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			inputLogin    : '',
			inputPassword : '',
			isLoading     : false
		};
		this._handleChangeLogin    = this._handleChangeLogin.bind(this);
		this._handleChangePassword = this._handleChangePassword.bind(this);
		this._onConnect            = this._onConnect.bind(this);
	}
	componentDidMount(){
		this._checkUserIsAuth();
	}
	
	/* Check User is auth */
	async _checkUserIsAuth() {
		auth.onAuthStateChanged(async (user)  => {
			if(user){
				let canAccess = await apiService.getUserCanAccess(user.uid);
				if(canAccess){
					this.props.history.push('/main'); 
				}else{
					stringService.toast(Constants.UNOTHORIZED);
					auth.signOut();
				}
			}else this.setState({ isLoading : false }) 
		});
	}
	
	/* Get value login */
	_handleChangeLogin(event) {
		this.setState({inputLogin: event.target.value});
	}

	/* Get value Password */
	_handleChangePassword(event) {
		this.setState({inputPassword: event.target.value});
	}

	/* Submit values */
	_onConnect() {
		let {inputLogin, inputPassword} = this.state;
		if(inputLogin !== "" && inputPassword !== ""){
			auth.signInWithEmailAndPassword(inputLogin, inputPassword).catch((e)=>{
				stringService.toast(Constants.PASSWORD_INCORRECT);
			});
		}else stringService.toast(Constants.PASSWORD_INCORRECT);
	}

	render(){
		let{isLoading} = this.state;
		return (
			<div className="login-container">
				{!isLoading &&(<>
					<div className="panel-row">
					{/* Logo */}
						<div className="panel-logo">
							<img src={iconMyBootlid} className="panel-logo" alt="MyBootlid" />
						</div>

					{/* Inputs */}
						<div className="panel-inputs">
							<div className="panel-input">
								<h5 className="panel-input-text">Mail</h5>
								<input value={this.state.inputLogin} onChange={this._handleChangeLogin} id="txtLogin" type="text" className="panel-input-input"></input>
							</div>
							<div className="panel-input">
								<h5 className="panel-input-text">Mot de passe</h5>
								<input value={this.state.inputPassword} onChange={this._handleChangePassword} id="txtPassword" type="password"></input>
							</div>
						</div>

					{/* Error message */}
						{this.state.errorMessage !== '' && (<div className="panel-errorMessage">{this.state.errorMessage}</div>)}

					{/* Input button connection */}
						<div className="panel-button">
							<input type="submit" value="Connexion" className="main-button" onClick={this._onConnect}></input>
						</div>
					</div>
				</>)}
				{isLoading &&(<InfoComponent isLoading={true} text={Constants.LOADING}></InfoComponent>)}
			</div>
		);
	}
}

export default withRouter(LoginPage);

