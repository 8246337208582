// ------------------------------------- //
// ---------------- ENV ---------------- //
// ------------------------------------- //

export const ENV = "prod";

// ------------------------------------- //
// ------------- FIREBASE -------------- //
// ------------------------------------- //

/* ----- Storage ----- */
export const FB_UNKNOWN     = "storage/unknown";
export const FB_NOT_FOUND   = "storage/object-not-found";
export const FB_UNAUTH      = "storage/unauthenticated";
export const FB_UNAUTORIZED = "storage/storage/unauthorized";
export const FB_LIMIT       = "storage/retry-limit-exceeded";
export const FB_QUOTA       = "storage/quota-exceeded";
export const FB_CANCELED    = "storage/canceled";
export const FB_INVALID_URL = "storage/invalid-url";

// ------------------------------------- //
// --------------- LOGIN --------------- //
// ------------------------------------- //

export const PASSWORD_INCORRECT = "Identifiant ou mot de passe incorrect.";
export const UNOTHORIZED        = "Vous n'êtes pas autorisé à accéder à ce contenu.";


// ------------------------------------- //
// --------------- DRAWER -------------- //
// ------------------------------------- //

export const SERVICES_PRICES = "Prix des services";
export const HISTORY         = "Historique des dépannages";
export const USERS           = "Utilisateurs";
export const FAQ             = "FAQ";
export const CANCEL          = "Annulations";

// ------------------------------------- //
// --------------- PRICES -------------- //
// ------------------------------------- //

export const MULTI_PART_BATTERY_SAVED  = "Multiplicateur batterie (particulier) modifié";
export const MULTI_PART_FUEL_SAVED     = "Multiplicateur panne d'essence (particulier) modifié";
export const MULTI_PART_OBS_SAVED      = "Multiplicateur constat (particulier) modifié";
export const MULTI_PART_PUNCTURE_SAVED = "Multiplicateur crevaison (particulier) modifié";
export const MULTI_PRO_BATTERY_SAVED   = "Multiplicateur batterie (pro) modifié";
export const MULTI_PRO_FUEL_SAVED      = "Multiplicateur panne d'essence (pro) modifié";
export const MULTI_PRO_OBS_SAVED       = "Multiplicateur constat (pro) modifié";
export const MULTI_PRO_PUNCTURE_SAVED  = "Multiplicateur crevaison (pro) modifié";
export const MULTI_REMORQ_SAVED        = "Multiplicateur remorquage modifié";

export const PART_BATTERY_SAVED  = "Prix batterie (particulier) modifié";
export const PART_FUEL_SAVED     = "Prix panne d'essence (particulier) modifié";
export const PART_OBS_SAVED      = "Prix constat (particulier) modifié";
export const PART_PUNCTURE_SAVED = "Prix crevaison (particulier) modifié";
export const PRO_BATTERY_SAVED   = "Prix batterie (pro) modifié";
export const PRO_FUEL_SAVED      = "Prix panne d'essence (pro) modifié";
export const PRO_OBS_SAVED       = "Prix constat (pro) modifié";
export const PRO_PUNCTURE_SAVED  = "Prix crevaison (pro) modifié";
export const REMORQ_SAVED        = "Prix remorquage modifié";

export const PUNCTURE     = "Crevaison";
export const BATTERY      = "Batterie";
export const FUEL         = "Panne d'essence";
export const OBSERVATION  = "Constat";
export const REMORQ       = "Remorquage";

// ------------------------------------- //
// -------------- USERS ---------------- //
// ------------------------------------- //

export const REPAIRED  = "Dépannés";
export const REPAIRMAN = "Dépanneurs";


// ------------------------------------- //
// ------------ REPARIMAN -------------- //
// ------------------------------------- //

export const DRIVER_LICENCE   = "driverLicence";
export const ASSURANCE        = "assurance";
export const VEHICLE_REG      = "vehicleRegistration";
export const KBIS             = "kbis";
export const NO_FILE_SELECTED = "Aucun fichier sélectionné.";


// ------------------------------------- //
// -------------- GENERAL -------------- //
// ------------------------------------- //

export const LOADING     = "Chargement...";
export const UNLOGGED    = "Vous n'êtes pas connecté.";

