/* Plugins */
import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css';
/* Common */
import * as Constants from '../common/Constants';

class StringService{

   /* Return the string with the first letter in uppercase */
   uppercaseFirstLetter(string){
      if(string === undefined) return "";
      let stpLowerCase = string.toLowerCase();
      if(stpLowerCase) return stpLowerCase.charAt(0).toUpperCase() + stpLowerCase.slice(1);
   }

   /* Display a toast */
   toast(text){
      return toaster.notify(text, {position:'bottom-left'})
   }

   /* Sort users by name */
   sortByValue(arr, value){
      return arr.sort((a,b) => (a[value] === undefined || b[value] === undefined) ? 0 : (a[value].toUpperCase() > b[value].toUpperCase()) ? 1 : ((b[value].toUpperCase() > a[value].toUpperCase()) ? -1 : 0))
   }

   /* Format to french date (dd/mm/yyyy) */
   getCurrentFRDate(){
      let date_ob = new Date();
      let date = ("0" + date_ob.getDate()).slice(-2);
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
      let year = date_ob.getFullYear();
      let fullDate = date+"/"+month+"/"+year;
      return fullDate;
   }

   /* Sort users by name */
   getFirebaseStorageError(code){
      let res = "";
      switch (code) {
         case Constants.FB_UNKNOWN     : res = "Une erreur est survenue."; break;
         case Constants.FB_NOT_FOUND   : res = "Fichier introuvable."; break;
         case Constants.FB_UNAUTH      : res = "Vous n'êtes pas authentifié.";  break;
         case Constants.FB_UNAUTORIZED : res = "Vous n'êtes pas autorisé à accéder à ce document."; break;
         case Constants.FB_LIMIT       : res = "Limite d'opérations atteinte. Veuillez recommencer plus tard."; break;
         case Constants.FB_QUOTA       : res = "Limite de quota atteinte. Veuillez recommencer plus tard."; break;
         case Constants.FB_CANCELED    : res = "Vous avez annulé l'opération"; break;
         case Constants.FB_INVALID_URL : res = "Le fichier demandé est inaccessible."; break;
         default : res = "Une erreur est survenue.";
      }
      return res;
   }
}

export default StringService;